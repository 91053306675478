import React from "react"
import Squeeze from "../components/squeeze"
import styles from "./buy.module.css"
import Base from "../components/base"
import SEO from "../components/SEO"

export default props => (
  <Base location={props.location}>
    <SEO title="404 | Page Not Found" />
    <div className="container">
      <div className="row my-3 text-center">
        <div className="twelve columns">
          <img
            src="https://res.cloudinary.com/iwh/image/upload/q_auto,g_center/assets/1/7/dash-writing-example.jpg"
            className={`${styles.dealerHeader} img-fluid`}
          />
        </div>
      </div>
      <div className="row my-3 text-center">
        <div className="twelve columns">
          <h1>404 - We Cant Find That Page</h1>
          <p>
            Let our team help you find what you are looking for. Please contact
            us via chat or one of our authorized distributors for assistance.
          </p>
        </div>
      </div>
    </div>
  </Base>
)
